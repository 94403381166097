.card {
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    z-index: 999;
    height: fit-content;
    flex-wrap: wrap;
}

.result-element {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }

    .result-title {
        color: $dark-grey;
        font-weight: bold;
    }

    .result-body {
        margin-top: 5px;
        font-weight: 500;
        font-size: $result-font-size;
    }
}

.divider {
    height: 50px;
    width: 1px;
    background-color: $dark-grey;
    display: none;
}

@media screen and (min-width: 1440px) {
    .card {
        flex-wrap: nowrap;
    }

    .divider {
        display: block;
    }
}
