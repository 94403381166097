@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import './variables';
@import './searchbar';
@import './searchresult';
@import './map';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'Rubik', sans-serif;
    font-size: $font-size;
    color: $very-dark-grey;
}

h1 {
    font-size: $font-size + 10;
}

h2 {
    font-size: $font-size + 5;
}

h3 {
    color: $dark-grey;
    font-weight: 400;
    font-size: $font-size - 5;
    text-transform: uppercase;
}

input {
    font-family: 'Rubik', sans-serif;;
}

.f-wrap {
    flex-wrap: wrap;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px;
}

.heading {
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.text-center {
    text-align: center;
}

.white-text {
    color: white;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.box-shadow {
   box-shadow: 0px 10px 20px 5px rgba(0,0,0,0.15);
}